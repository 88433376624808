<template>
  <div>
    <div class="d-flex justify-space-between mb-8">
      <div class="titulo-pagina">
        <div>
          {{ tituloPagina }}
        </div>
        <div>
          <v-chip
            v-if="id"
            class="mr-1"
            small
          >
            Código: {{ badges.codigo }}
          </v-chip>
          <v-chip
            v-if="form?.ordemServico?.text"
            class="mr-1"
            small
          >
            Ordem de Serviço: {{ badges.ordemServico }}
          </v-chip>
        </div>
      </div>
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab :disabled="ehRegistroEvento">
        {{ $t('modulos.agendamento.formulario.abas.dados') }}
      </v-tab>
      <v-tab
        :disabled="
          form?.contratante?.flagParticipanteConta ||
          !form?.contratante ||
          ehRegistroEvento
        "
      >
        {{ $t('modulos.agendamento.formulario.abas.instrumentos') }}
      </v-tab>
      <v-tab
        v-if="ehProgramar"
        @click="encontrarHorarioAtualizarDatas"
      >
        {{ $t('modulos.agendamento.formulario.abas.encontrar_horario') }}
      </v-tab>
      <v-tab v-if="ehExecutar">
        {{ $t('modulos.agendamento.formulario.abas.operacoes') }}
      </v-tab>
      <v-tab
        v-if="
          ehExecutar || ehRegistroEvento || ehVisualizar || ehAnaliseCritica
        "
      >
        {{ $t('modulos.agendamento.formulario.abas.resumo') }}
      </v-tab>
      <v-tab v-if="ehAnaliseCritica">
        {{ $t('modulos.agendamento.formulario.abas.analise_critica') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="indiceAba">
      <v-tab-item :disabled="ehRegistroEvento">
        <dados-agendamento
          ref="dados-agendamento"
          :form="form"
          :eh-visualizar="ehVisualizar"
          :eh-programar="ehProgramar"
          :eh-confirmar="ehConfirmar"
          :eh-executar="ehExecutar"
          :eh-analise-critica="ehAnaliseCritica"
        />
      </v-tab-item>
      <v-tab-item
        :disabled="
          form?.contratante?.flagParticipanteConta ||
          !form?.contratante ||
          ehProgramar ||
          ehConfirmar ||
          ehRegistroEvento
        "
      >
        <Instrumentos
          :form="form"
          :eh-visualizar="ehVisualizar"
          :eh-programar="ehProgramar"
          :eh-confirmar="ehConfirmar"
          :eh-executar="ehExecutar"
          :eh-analise-critica="ehAnaliseCritica"
        />
      </v-tab-item>
      <v-tab-item v-if="ehProgramar">
        <encontrar-horario
          ref="encontrar-horario"
          :form="form"
        />
      </v-tab-item>
      <v-tab-item v-if="ehExecutar">
        <Operacoes
          :eh-agendamento="true"
          :codigo-o-s-agendamento="form?.ordemServico?.text"
        />
      </v-tab-item>
      <v-tab-item
        v-if="
          ehExecutar || ehRegistroEvento || ehVisualizar || ehAnaliseCritica
        "
      >
        <Resumo
          :form="form"
          :eh-registro-evento="ehRegistroEvento"
          :eh-visualizar="ehVisualizar"
          :eh-analise-critica="ehAnaliseCritica"
        />
      </v-tab-item>
      <v-tab-item v-if="ehAnaliseCritica">
        <analise-critica
          :id="id"
          ref="analise-critica"
          :form="form"
          :eh-analise-critica="ehAnaliseCritica"
          :eh-visualizar="ehVisualizar"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        v-if="ehVisualizar || ehRegistroEvento"
        outlined
        color="secondary"
        class="mr-2"
        @click="voltar"
      >
        <v-icon>$mdiArrowLeft</v-icon>
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>

      <template v-else>
        <botao-padrao
          outlined
          color="secondary"
          class="mr-2"
          @click="cancelar"
        >
          <v-icon>$mdiCancel</v-icon>
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>
        <botao-padrao
          v-if="ehProgramar"
          @click="programarAgendamento"
        >
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvar') }}
        </botao-padrao>
        <botao-padrao
          v-else-if="ehConfirmar"
          @click="confirmarAgendamento"
        >
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvar') }}
        </botao-padrao>

        <botao-padrao
          v-else-if="ehAnaliseCritica && indiceAba == 3"
          @click="confirmarAnaliseCritica"
        >
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvar') }}
        </botao-padrao>

        <template v-if="ehExecutar">
          <botao-padrao
            v-if="indiceAba == 1"
            class="mr-2"
            @click="alterarInstrumentos"
          >
            <v-icon>$mdiContentSaveOutline</v-icon>
            {{ $t('modulos.agendamento.botoes.salvar_instrumentos') }}
          </botao-padrao>
          <botao-padrao
            v-if="form.flagPodeCheckIn"
            class="mr-2"
            @click="fazerCheckIn"
          >
            <v-icon>$mdiContentSaveOutline</v-icon>
            {{ $t('geral.botoes.checkIn') }}
          </botao-padrao>
          <botao-padrao
            v-if="form.flagPodeIniciarAtendimento"
            class="mr-2"
            @click="iniciarAtendimentoConfirmar"
          >
            <v-icon>$mdiContentSaveOutline</v-icon>

            {{ $t('geral.botoes.iniciar_atendimento') }}
          </botao-padrao>
          <botao-padrao
            v-if="indiceAba == 3 && form.flagPodeFinalizarAtendimento"
            class="mr-2"
            @click="finalizarAtendimento"
          >
            <v-icon>$mdiContentSaveOutline</v-icon>

            {{ $t('geral.botoes.finalizar_atendimento') }}
          </botao-padrao>
          <botao-padrao
            v-if="form.flagPodeCheckOut"
            @click="fazerCheckOut"
          >
            <v-icon>$mdiContentSaveOutline</v-icon>

            {{ $t('geral.botoes.checkOut') }}
          </botao-padrao>
        </template>

        <botao-padrao
          v-if="
            !ehProgramar &&
              !ehConfirmar &&
              !ehExecutar &&
              !ehRegistroEvento &&
              !ehAnaliseCritica
          "
          @click="confirmacaoSalvar"
        >
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvar') }}
        </botao-padrao>
      </template>
      <botao-padrao
        v-if="
          ehRegistroEvento &&
            !this.$store.getters['Autenticacao/participanteLogadoConta']
        "
        class="mr-2"
        @click="aprovarAgendamento"
      >
        <v-icon>$mdiCheck</v-icon>
        {{ $t('geral.botoes.aprovar') }}
      </botao-padrao>
    </div>
  </div>
</template>

<script>
import { AgendamentoModel } from '@/common/models/cadastros/AgendamentoModel.js';
import AgendamentoService from '@/common/services/cadastros/AgendamentoService.js';
import DadosAgendamento from './components/DadosAgendamento';
import Instrumentos from './components/Instrumentos';
import EncontrarHorario from './components/EncontrarHorario';
import Operacoes from '../operacao';
import Resumo from './components/Resumo.vue';
import analiseCritica from './components/AnaliseCritica';
import helpers from '@common/utils/helpers';
export default {
  components: {
    DadosAgendamento,
    Instrumentos,
    EncontrarHorario,
    Operacoes,
    Resumo,
    analiseCritica,
  },
  props: ['id'],
  data() {
    return {
      badges: {
        codigo: '',
        ordemServico: '',
      },
      indiceAba: 0,
      form: new AgendamentoModel({}),
    };
  },
  computed: {
    tituloPagina: function () {
      if (this.$route.name == 'agendamento-analise-critica')
        return this.$t('modulos.agendamento.titulos.analise_critica');
      if (this.$route.name == 'agendamento-registro-eventos')
        return this.$t('modulos.agendamento.titulos.registar_eventos');
      if (this.$route.name == 'agendamento-executar')
        return this.$t('modulos.agendamento.titulos.executar');
      if (this.$route.name == 'agendamento-confirmar')
        return this.$t('modulos.agendamento.titulos.confirmar');
      if (this.$route.name == 'agendamento-programar')
        return this.$t('modulos.agendamento.titulos.programar');
      if (this.$route.name == 'agendamento-visualizar')
        return this.$t('modulos.agendamento.titulos.visualizar');
      if (this.$route.name == 'agendamento-editar')
        return this.$t('modulos.agendamento.titulos.editar');
      if (this.$route.name == 'agendamento-novo')
        return this.$t('modulos.agendamento.titulos.novo');
      return this.$t('modulos.agendamento.titulos.visualizar');
    },

    ehVisualizar() {
      return this.$route.name == 'agendamento-visualizar';
    },
    ehProgramar() {
      return this.$route.name == 'agendamento-programar';
    },
    ehConfirmar() {
      return this.$route.name == 'agendamento-confirmar';
    },
    ehExecutar() {
      return this.$route.name == 'agendamento-executar';
    },
    ehRegistroEvento() {
      return this.$route.name == 'agendamento-registro-eventos';
    },
    ehAnaliseCritica() {
      return this.$route.name == 'agendamento-analise-critica';
    },
  },
  async mounted() {
    helpers.redirecionarSemPermissao(this, 'Agendamento', 'Inserir');
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.agendamento.titulos.listagem')
    );

    await this.buscarInicializarWatchers();

    if (this.ehExecutar) this.indiceAba = 2;
    if (this.ehRegistroEvento) this.indiceAba = 2;
    if (this.ehAnaliseCritica) this.indiceAba = 3;
  },
  methods: {
    encontrarHorarioAtualizarDatas: async function(){
      await this.$nextTick();
      await this.$nextTick();
      this.$refs['encontrar-horario']?.buscarDados();
    },
    buscar: async function (id) {
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Buscando agendamento'
      );
      await AgendamentoService.buscar(id)
        .then((response) => {
          this.form = new AgendamentoModel(response.data);
          this.badges.codigo = this.form.codigo;
          this.badges.ordemServico = this.form?.ordemServico?.text;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarInicializarWatchers: async function () {
      if (this.id) await this.buscar(this.id);
      setTimeout(() => {
        if (this.id)
          this.$refs['dados-agendamento'].buscarContatoParticipante(
            this.form.contratante.value
          );
        this.$refs['dados-agendamento'].definirWatchers();
      }, 100);
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      const { dadosValido, temFimDeSemanaOuFeriado } =
        this.$refs['dados-agendamento'].salvarDados();
      if (!dadosValido) return;

      if (!this.form.contratante.flagParticipanteConta) {
        if (this.form.instrumentos.length < 1) {
          this.toastAlerta(
            this.$t(
              'modulos.agendamento.formulario.dados.mensagens.insira_instrumentos'
            ),
            'warning'
          );
          this.indiceAba = 1;
          return;
        }
      }

      if (temFimDeSemanaOuFeriado) {
        this.confirmar(
          this.$t('geral.titulos.atencao'),
          this.$t('modulos.agendamento.alertas.alerta_feriado')
        ).then(() => {
          this.$store.dispatch(
            'Layout/iniciarCarregamento',
            'Salvando agendamento'
          );
          AgendamentoService.salvar(this.form.request)
            .then(() => {
              this.toastSucesso(
                this.$t(`modulos.agendamento.cadastro_sucesso`)
              );
              this.$router.push({ name: 'agendamento' });
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
            });
        });
        return;
      }
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Salvando agendamento'
      );
      AgendamentoService.salvar(this.form.request)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.agendamento.cadastro_sucesso`));
          this.$router.push({ name: 'agendamento' });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    programarAgendamento: function () {
      const { dadosValido } = this.$refs['dados-agendamento'].salvarDados();
      if (!dadosValido) return;
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Programando agendamento'
      );
      AgendamentoService.programar(this.form.programar)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.agendamento.programado_sucesso`));
          this.$router.push({ name: 'agendamento' });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmarAgendamento: function () {
      const { dadosValido } = this.$refs['dados-agendamento'].salvarDados();
      if (!dadosValido) return;
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Confirmando Agendamento'
      );
      AgendamentoService.confirmar(this.form.confirmar)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.agendamento.confirmado_sucesso`));
          this.$router.push({ name: 'agendamento' });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmarAnaliseCritica: function () {
      this.confirmar(
        this.$t('modulos.agendamento.alertas.confirmar_analise_critica'),
        this.$t(
          'modulos.agendamento.alertas.confirmar_analise_critica_mensagem'
        )
      ).then(() => {
        this.$refs['analise-critica'].salvar();
      });
    },
    aprovarAgendamento: function () {
      this.confirmar(
        this.$t('modulos.agendamento.alertas.confirmar_aprovacao'),
        this.$t('modulos.agendamento.alertas.confirmar_aprovacao_mensagem')
      ).then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento', 'Aprovando');
        AgendamentoService.aprovar(this.form.id)
          .then(() => {
            this.toastSucesso(this.$t(`modulos.agendamento.aprovado_sucesso`));
            this.$router.push({ name: 'agendamento' });
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    alterarInstrumentos: function () {
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Alterando instrumentos'
      );
      AgendamentoService.alterarInstrumentos(this.form.alterarInstrumentos)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.agendamento.alterado_instrumentos_sucesso`)
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    fazerCheckIn: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento', 'Fazendo Check-In');
      await AgendamentoService.checkin(this.form.id)
        .then(async () => {
          this.toastSucesso(this.$t(`modulos.agendamento.check_in_sucesso`));
          await this.buscarInicializarWatchers();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    fazerCheckOut: async function () {
      this.confirmar(
        this.$t('modulos.agendamento.alertas.confirmar_checkout'),
        this.$t('modulos.agendamento.alertas.confirmar_checkout_mensagem')
      ).then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento', 'Fazendo Check-out');
        AgendamentoService.checkout(this.form.checkOutJustificativa)
          .then(() => {
            this.toastSucesso(this.$t(`modulos.agendamento.check_out_sucesso`));
            this.$router.push({ name: 'agendamento' });
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    iniciarAtendimentoConfirmar: function () {
      if (this.form.flagPodeCheckIn) {
        this.confirmar(
          this.$t('modulos.agendamento.alertas.confirmar_inicio_atendimento'),
          this.$t(
            'modulos.agendamento.alertas.confirmar_inicio_atendimento_mensagem'
          )
        ).then(() => {
          this.iniciarAtendimento();
        });
        return;
      }
      this.iniciarAtendimento();
    },
    iniciarAtendimento: async function () {
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Iniciando Atendimento'
      );
      await AgendamentoService.iniciarAtendimento(this.form.id)
        .then(async () => {
          this.toastSucesso(
            this.$t(`modulos.agendamento.iniciar_atendimento_sucesso`)
          );
          await this.buscarInicializarWatchers();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    finalizarAtendimento: function () {
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Finalizando Atendimento'
      );
      AgendamentoService.finalizarAtendimento(this.form.id)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.agendamento.finalizar_atendimento_sucesso`)
          );
          this.$router.push({ name: 'agendamento' });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'agendamento' });
      });
    },
    voltar: function () {
      this.$router.push({ name: 'agendamento' });
    },
  },
};
</script>

<style scoped></style>
