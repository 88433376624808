<template lang="">
  <div class="mt-5">
    <div
      v-if="!ehVisualizar"
      class="d-flex align-center justify-space-between mb-3"
    >
      <div>
        <botao-padrao
          v-if="podeExcluir"
          class="my-2"
          outlined
          color="danger"
          :disabled="ehVisualizar"
          @click="excluir"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <div class="d-flex">
        <botao-padrao
          :disabled="ehVisualizar || !podeInserirInstrumentos || ehProgramar"
          @click="() => abreModalAdicionaInstrumento()"
        >
          {{
            $t('modulos.envio_material.instrumentos.botoes.novo_instrumento')
          }}
        </botao-padrao>
        <botao-padrao
          class="ml-2"
          :disabled="ehVisualizar || !podeInserirInstrumentos || ehProgramar"
          @click="associarDesassociarInstrumento"
        >
          {{
            $t(
              'modulos.envio_material.instrumentos.botoes.selecionar_instrumentos'
            )
          }}
        </botao-padrao>
      </div>
    </div>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      sem-paginacao
      :mostrar-acoes="false"
      ajustar-altura-linha
      :mostrar-seletor="false"
      mostrar-seletor-check-box
    >
      <template v-slot:seletor="{ slotProps }">
        <div class="pl-2">
          <v-simple-checkbox
            v-if="podeSelecionar(slotProps.data)"
            v-model="slotProps.data.isSelected"
            @input="
              selecionarInstrumento(slotProps.data, slotProps.data.isSelected)
            "
          />
        </div>
      </template>
    </tabela-padrao-prime-vue>
    <instrumento-modal
      ref="modal-instrumento"
      :participante-id="idParticipanteInstrumentos"
      :value="tabela.dados"
      @associarInstrumentos="associarInstrumentos"
    />
    <criacao-instrumento-modal
      ref="modal-criacao-instrumento"
      @novoInstrumento="associarNovoInstrumento"
    />
  </div>
</template>
<script>
import InstrumentoModal from './modais/InstrumentoModal.vue';
import CriacaoInstrumentoModal from './modais/CriacaoInstrumentoModal.vue';
import { mapGetters } from 'vuex';

export default {
  components: { InstrumentoModal, CriacaoInstrumentoModal },
  props: {
    form: {},
    ehVisualizar: { type: Boolean, default: false },
    ehProgramar: { type: Boolean, default: false },
  },
  data() {
    return {
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'codigoTag',
            text: this.$t('modulos.envio_material.instrumentos.tabela.codigo'),
            sortable: true,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t(
              'modulos.envio_material.instrumentos.tabela.tipo_instrumento'
            ),
            sortable: true,
          },
          {
            value: 'numeroSerie',
            text: this.$t(
              'modulos.envio_material.instrumentos.tabela.numero_serie'
            ),
            sortable: true,
          },
          {
            value: 'quantidadeInstrumentoFaixas',
            text: this.$t(
              'modulos.envio_material.instrumentos.tabela.qtd_faixas'
            ),
            sortable: true,
          },
        ],
        selecionados: [],
      },
      tooltip: this.$t('modulos.envio_material.aprovacao_criar_instrumento'),
    };
  },
  computed: {
    ...mapGetters('Autenticacao', [
      'participanteLogadoConta',
      'participanteSelecionado',
    ]),
    podeExcluir() {
      return this.tabela.selecionados != 0;
    },
    podeInserirInstrumentos() {
      return !!this.form?.solicitante?.value || !!this.form?.contratante?.value;
    },
    idParticipanteInstrumentos() {
      return this.form.solicitante?.value;
    },
  },
  watch: {
    form: {
      handler() {
        this.tabela.dados = this.form.instrumentos;
      },
      deep: true,
    },
  },
  mounted() {
    this.tabela.dados = this.form.instrumentos;
  },
  methods: {
    associarDesassociarInstrumento() {
      this.$refs['modal-instrumento'].abrirModal(this.form);
    },
    abreModalAdicionaInstrumento(instrumentoIdClonar) {
      this.$refs['modal-criacao-instrumento'].abrirModal(
        instrumentoIdClonar,
        this.form.solicitanteId
      );
    },
    associarInstrumentos(instrumentos) {
      instrumentos.forEach((instrumento) => {
        const jaInserido = this.form.instrumentos.some(
          (el) => el.id === instrumento.id
        );
        if (jaInserido) return;
        this.form.instrumentos.push({
          ...instrumento,
          id: instrumento.id,
          codigo: instrumento.codigo,
          participante: instrumento.participanteCliente,
        });
      });
      this.tabela.dados = this.form.instrumentos;
    },
    associarNovoInstrumento(instrumento) {
      if (!instrumento.id) return;
      const participante = {
        id: instrumento.participanteCliente?.value,
        nome: instrumento.participanteCliente?.text,
      };
      this.form.instrumentos.push({
        ...instrumento,
        id: instrumento.id,
        codigo: instrumento.codigoTag,
        participante,
      });
      this.tabela.dados = this.form.instrumentos;
    },
    excluir: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.instrumentos.splice(
            this.form.instrumentos.indexOf(element),
            1
          );
        });
        this.tabela.selecionados = [];
      });
    },
    selecionarInstrumento: function (item, selecionado) {
      if (!selecionado) {
        this.tabela.selecionados.splice(
          this.tabela.selecionados.indexOf(
            this.tabela.selecionados.find((el) => el.id == item.id)
          ),
          1
        );
      } else {
        this.tabela.selecionados.push(item);
      }
    },
    podeSelecionar(item) {
      if (this.ehVisualizar) return false;
      return !item.flagInseridoDuranteAgendamento;
    },
  },
};
</script>
<style>
.div-clonar-instrumento-ej2kdjiuw {
  display: flex;
  align-items: center;
  gap: 10px;
}
.div-clonar-instrumento-botao-ej2kdjiuw {
  text-transform: capitalize;
  padding: 3px !important;
  min-width: 0px !important;
  height: 20px !important;
}
</style>
