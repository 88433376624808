<template>
  <section>
    <div
      class="d-flex align-center"
      style="gap: 10px; height: 80px;"
    >
      <div class="ml-4">
        <div class="d-flex align-center" style="gap: 5px;">
          <div class="div-cor" />
          <span> Branco = fora do expediente de trabalho </span>
        </div>
        <div class="d-flex align-center" style="gap: 5px;">
          <div class="div-cor" style="background-color: #3468C0;"/>
          <span> Azul = existe agendamento de reserva </span>
        </div>
      </div>
      <div class="ml-2">
        <div class="d-flex align-center" style="gap: 5px;">
          <div class="div-cor" style="background-color: #F2C94C;"/>
          <span> Amarelo = existe agendando aguardando confirmação do cliente </span>
        </div>
        <div class="d-flex align-center" style="gap: 5px;">
          <div class="div-cor" style="background-color: #D24545;"/>
          <span> Vermelho = existe agendamento programado com cliente </span>
        </div>
      </div>
    </div>
    <div
      id="calendar"
      style="height: 800px"
    />
  </section>
</template>

<script>
import Calendar from '@toast-ui/calendar';
import { TZDate } from '@toast-ui/calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import AgendamentoService from '@/common/services/cadastros/AgendamentoService.js';
import ParametroService from '@/common/services/cadastros/ParametroService.js';
import helpers from '@/common/utils/helpers.js';
// import _ from 'lodash';

export default {
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      calendar: null,
      events: [],
      flagConsiderarDiasUteisPrazoEntrega: false,
    };
  },
  mounted() {
    this.iniciarCalendario();
    this.buscarParametrosConta();
    document.a = new TZDate('2021-10-01T00:00:00Z');
  },
  methods: {
    buscarParametrosConta: async function () {
      await ParametroService.buscar()
        .then((res) => {
          this.flagConsiderarDiasUteisPrazoEntrega = res.data.flagConsiderarDiasUteisPrazoEntrega;
        })
    },
    buscarDados: async function() {
      if(this.calendar) this.calendar.clear();
      else this.iniciarCalendario();
      
      await this.buscarParametrosConta();
      this.events = [];

      await this.$nextTick();
      const colaboradoresId = this.form.colaboradores.map((el) => el.value);
      const instrumentosPadroesId = this.form.instrumentoPadrao.map(
        (el) => el.value
      );

      if(!colaboradoresId.length || !instrumentosPadroesId.length || !this.form.veiculo) return;
      AgendamentoService.buscarDatasProgramadas(
        colaboradoresId,
        instrumentosPadroesId,
        this.form.veiculo.value,
        this.form.dataInicial
      ).then((res) => {
        this.inserirEventosAgenda(res.data);
      });
    },
    iniciarCalendario() {
      function converterModeloHoras(start, end) {
            function modeloMinutos(v) {
              return v < 10 ? `0${v}` : v;
            }

            return `${start.getHours()}:${modeloMinutos(start.getMinutes())} -
       ${end.getHours()}:${modeloMinutos(end.getMinutes())}`;
          }
      const dataInicialTz = new TZDate(this.form.dataInicial);
      this.calendar = new Calendar('#calendar', {
        defaultView: 'week',
        isReadOnly: true,
        gridSelection: false,
        useDetailPopup: true,
        week: {
          dayNames: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          startDayOfWeek: dataInicialTz.getDay(),
          defaultView: 'week',
          hourStart: 6,
          hourEnd: 22,
          eventView: ['time'],
          taskView: false,
        },
        // timezone: {
        //   zones: [
        //     {
        //       // timezoneName: 'Europe/London',
        //       // timezoneName: 'America/Sao_Paulo',
        //     },
        //   ],
        // },
        theme: {
          week: {
            today: {
              color: '#3468C0'
            },
          }
        },
        template: {
          popupDetailAttendees() {
            return 'Agendamento';
          },
          popupDetailState(event = null) {
            return event?.state
          },
          timegridDisplayPrimaryTime(time) {
            return `<span>${time.time.getHours()}:00</span>`;
          },
          time(event) {
            const { start, end, title } = event;

            return `<div style="color: black;"><div>${title}</div><div>${converterModeloHoras(
              start,
              end
            )}</div></div>`;
          },
          popupDetailDate({ start, end }) {
            return `<span style="color: black; font-weight: 500;">${converterModeloHoras(start, end)}</span>`;
          },
        },
        calendars: [
          {
            id: 'Vemelho',
            name: 'Vermelho',
            backgroundColor: '#D24545',
          },
          {
            id: 'Azul',
            name: 'Azul',
            backgroundColor: '#3468C0',
          },
          {
            id: 'Amarelo',
            name: 'Amarelo',
            backgroundColor: '#F2C94C',
          },
          {
            id: 'Branco',
            name: 'Branco',
            backgroundColor: '#FFF',
          },
        ],
      });
    },
    toTzDate(date) {
      return new TZDate(date).tz('Europe/London');
    },
    inserirEventosAgenda(data) {

      this.criarEventosColaboradores(data.colaboradores);
      this.criarEventosInstrumentos(data.instrumentos);
      this.criarEventosVeiculos(data.veiculo);
      console.log(this.events.map(el => el.title));
      this.calendar.createEvents(this.events);
    },
    criarEventosColaboradores(colaboradores){

      colaboradores?.forEach((colaborador) => {
        this.criarEventosForaExpediente(colaborador);
        colaborador?.datasProgramadas?.forEach((programacao) => {
          this.events.push({
            id: helpers.gerarNumeroAleatorio().toString(),
            calendarId: programacao.disponibilidade,
            title: colaborador.nome,
            category: 'time',
            dueDateClass: '',
            start: this.toTzDate(programacao.dataInicial),
            end: this.toTzDate(programacao.dataFinal),
            state: programacao.agendamento.codigo
          });
        });
      });
    },
    criarEventosInstrumentos(instrumentos){
      instrumentos?.forEach((instrumento) => {
        instrumento?.datasProgramadas?.forEach((programacao) => {
          this.events.push({
            id: helpers.gerarNumeroAleatorio().toString(),
            calendarId: programacao.disponibilidade,
            title: instrumento.nome,
            category: 'time',
            dueDateClass: '',
            start: this.toTzDate(programacao.dataInicial),
            end: this.toTzDate(programacao.dataFinal),
            state: programacao.agendamento.codigo
          });
        });
      });
    },
    criarEventosVeiculos(veiculo){
        veiculo?.datasProgramadas?.forEach((programacao) => {
          this.events.push({
            id: helpers.gerarNumeroAleatorio().toString(),
            calendarId: programacao.disponibilidade,
            title: veiculo.nome,
            category: 'time',
            dueDateClass: '',
            start: this.toTzDate(programacao.dataInicial),
            end: this.toTzDate(programacao.dataFinal),
            state: programacao.agendamento.codigo
          });
        });
    },
    criarEventosForaExpediente(colaborador){
      if(!colaborador.horarioInicioIntervalo || !colaborador.horarioTerminoIntervalo) return;

      const dataInicialTz = new TZDate(this.form.dataInicial);
      const dataFinalTz = new TZDate(this.form.dataInicial);

      const horaInicioIntervalo = colaborador.horarioInicioIntervalo.split(':').map(el => parseFloat(el));
      const horaTerminoIntervalo = colaborador.horarioTerminoIntervalo.split(':').map(el => parseFloat(el));

      dataInicialTz.setHours(horaInicioIntervalo[0], horaInicioIntervalo[1]);
      dataFinalTz.setHours(horaTerminoIntervalo[0], horaTerminoIntervalo[1]);

      this.events.push({
        id: helpers.gerarNumeroAleatorio().toString(),
        calendarId: 'Branco',
        title: colaborador.nome,
        category: 'time',
        dueDateClass: '',
        start: dataInicialTz.toDate(),
        end: dataFinalTz.toDate(),
        state: 'Ausente'
      });

      for(let i = 0; i < 5; i++){
        dataInicialTz.addDate(1);
        dataFinalTz.addDate(1);

        if(dataInicialTz.getDay() === 0 || dataInicialTz.getDay() === 6) {
          this.criarEventosSabadoDomingo(colaborador, dataInicialTz.toDate(), dataFinalTz.toDate());
          continue
        }

        this.events.push({
          id: helpers.gerarNumeroAleatorio().toString(),
          calendarId: 'Branco',
          title: colaborador.nome,
          category: 'time',
          dueDateClass: '',
          start: dataInicialTz.toDate(),
          end: dataFinalTz.toDate(),
          state: 'Ausente'
        });
      }
    },
    criarEventosSabadoDomingo(colaborador, dataInicial, dataFinal){
      if(!this.flagConsiderarDiasUteisPrazoEntrega) return;
      const dataInicialTz = new TZDate(dataInicial);
      const dataFinalTz = new TZDate(dataFinal);

      dataInicialTz.setHours(6, 0);
      dataFinalTz.setHours(22, 0);

      this.events.push({
        id: helpers.gerarNumeroAleatorio().toString(),
        calendarId: 'Branco',
        title: colaborador.nome,
        category: 'time',
        dueDateClass: '',
        start: dataInicialTz.toDate(),
        end: dataFinalTz.toDate(),
        state: 'Ausente'
      });
    },
  },
};
</script>

<style scoped>
:deep(.toastui-calendar-detail-item) {
  font-weight: 600 !important;
}

:deep(.toastui-calendar-section-button) {
  display: none !important;
}

:deep(.toastui-calendar-columns) {
  background-color: rgba(9, 247, 124, 0.096);
}

.div-cor {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid gray;
}
</style>
