import {
  ParticipanteService,
  UsuarioService,
  ParametroService,
} from '../../../../../common/services/';
import {
  PrioridadeService,
  TipoVisitaService, FeriadoService
} from '@/common/services/cadastros';
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import { DropdownModelDoisParametros } from '@common/models/geral/DropdownModelDoisParametros';
import moment from 'moment';

export const MetodosBuscar = {
  methods: {
    buscarParticipanteLogado: async function (id) {
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Buscando participante'
      );
      await ParticipanteService.buscar(id)
        .then((res) => {
          if (!this.form.id) {
            this.form.contratante = new DropdownModelDoisParametros(
              res.data,
              'nome',
              'flagParticipanteConta'
            );

            this.form.solicitante = new DropdownModelDoisParametros(
              res.data,
              'nome',
              'flagParticipanteConta'
            );
          }
          this.clienteNaoEhLaboratorio = !res.data?.flagLaboratorio;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarContatoParticipante: async function (id) {
      this.$store.dispatch('Layout/iniciarCarregamento', 'Buscando contatos');
      await ParticipanteService.buscaParticipantesContatosPorId(id)
        .then((res) => {
          this.opcoes.contato = new DropdownModel(res.data);
          if (!this.participanteLogadoConta) {
            this.buscarContatoUsuarioSolicitante(this.usuario.id);
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarContatoUsuarioSolicitante: async function (id) {
      UsuarioService.buscar(id).then((res) => {
        if (res.data.contato)
          this.form.contatoContratanteId = new DropdownModel(res.data.contato);
      });
    },
    buscarPrioridades: async function () {
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Buscando Prioridades'
      );
      PrioridadeService.listar()
        .then((res) => {
          this.opcoes.prioridade = new DropdownModel(
            res.data.items.sort((a, b) => a.sequencia - b.sequencia)
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarTipoVisita: async function () {
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Buscando Tipo de Visita'
      );
      let parametros = {
        ExibirCompleto: true,
      };

      TipoVisitaService.listar(parametros)
        .then((res) => {
          this.opcoes.tipoVisita = res.data.items;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarParametrosDaConta: async function () {
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Buscando Parâmetros da conta'
      );
      await ParametroService.buscar()
        .then((res) => {
          this.prazoAgendamentoClientes = res.data.prazoAgendamentoClientes;
          this.dataAgendamentoMinimoParaClientes = moment()
            .add(this.prazoAgendamentoClientes, 'days')
            .format('YYYY-MM-DD');
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarFeriados: async function () {
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Buscando Feriados'
      );
      let parametros = {
        page: 1,
        amountPerPage: 99999,
        ...this.filtroAplicado,
      };
      FeriadoService.listar(parametros)
        .then((res) => {
          res.data.items.forEach((feriado) => {
            let { dia, mes, ano } = feriado
            ano = ano || moment().year()
            this.feriados.push(`${ano}-${mes}-${dia}`)
          });

        }).finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    }

  }
}