var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('v-form',{ref:"form",staticClass:"row"},[_c('input-participantes',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.contratante'),"titulo":_vm.$t('modulos.agendamento.formulario.dados.contratante'),"multiplos":false,"disabled":!_vm.participanteLogadoConta ||
          _vm.ehVisualizar ||
          _vm.ehProgramar ||
          _vm.ehConfirmar ||
          _vm.ehExecutar,"obrigatorio":""},model:{value:(_vm.form.contratante),callback:function ($$v) {_vm.$set(_vm.form, "contratante", $$v)},expression:"form.contratante"}}),_c('input-select',{staticClass:"col-12 col-md-2",attrs:{"options":_vm.opcoes.contato,"label":_vm.$t('modulos.agendamento.formulario.dados.contato'),"placeholder":_vm.$t('geral.inputs.selecione'),"obrigatorio":"","retornar-objeto":"","disabled":_vm.ehVisualizar || _vm.ehProgramar || _vm.ehConfirmar || _vm.ehExecutar},model:{value:(_vm.form.contatoContratante),callback:function ($$v) {_vm.$set(_vm.form, "contatoContratante", $$v)},expression:"form.contatoContratante"}}),_c('input-cliente',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.solicitante'),"multiplos":false,"disabled":!_vm.participanteLogadoConta ||
          _vm.clienteNaoEhLaboratorio ||
          _vm.ehVisualizar ||
          _vm.ehProgramar ||
          _vm.ehConfirmar ||
          _vm.ehExecutar,"obrigatorio":""},model:{value:(_vm.form.solicitante),callback:function ($$v) {_vm.$set(_vm.form, "solicitante", $$v)},expression:"form.solicitante"}}),_c('input-select',{staticClass:"col-12 col-md-2",attrs:{"options":_vm.tipoVisitaFiltrada,"label":_vm.$t('modulos.agendamento.formulario.dados.tipo_visita'),"placeholder":_vm.$t('geral.inputs.selecione'),"retornar-objeto":"","obrigatorio":"","disabled":_vm.ehVisualizar || _vm.ehConfirmar || _vm.ehExecutar},model:{value:(_vm.form.tipoVisita),callback:function ($$v) {_vm.$set(_vm.form, "tipoVisita", $$v)},expression:"form.tipoVisita"}}),_c('input-select',{staticClass:"col-12 col-md-2",attrs:{"options":_vm.opcoes.prioridade,"label":_vm.$t('modulos.agendamento.formulario.dados.prioridade'),"placeholder":_vm.$t('geral.inputs.selecione'),"retornar-objeto":"","clearable":"","disabled":_vm.ehVisualizar || _vm.ehExecutar},model:{value:(_vm.form.prioridade),callback:function ($$v) {_vm.$set(_vm.form, "prioridade", $$v)},expression:"form.prioridade"}}),_c('div',{staticClass:"row col-12"},[_c('accordion-padrao',{staticClass:"accordion-padrao col-12 col-md-4",attrs:{"alterar-cor":true,"cor-azul-texto":"","titulo":_vm.$t('modulos.agendamento.formulario.dados.sugestao_1'),"disabled":_vm.ehVisualizar || _vm.ehProgramar || _vm.ehConfirmar || _vm.ehExecutar}},[_c('div',{staticClass:"row"},[_c('input-date',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.data_inicial'),"data-minima":_vm.participanteLogadoConta
                ? new Date().toISOString().slice(0, 10)
                : _vm.dataAgendamentoMinimoParaClientes,"obrigatorio":_vm.form?.contratante?.flagParticipanteConta && !_vm.ehProgramar && !_vm.ehConfirmar,"disabled":_vm.ehVisualizar || _vm.ehProgramar || _vm.ehConfirmar || _vm.ehExecutar},model:{value:(_vm.form.dataSugerida1.dataInicial),callback:function ($$v) {_vm.$set(_vm.form.dataSugerida1, "dataInicial", $$v)},expression:"form.dataSugerida1.dataInicial"}}),_c('input-date',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.data_final'),"data-minima":_vm.participanteLogadoConta
                ? new Date().toISOString().slice(0, 10)
                : _vm.dataAgendamentoMinimoParaClientes,"obrigatorio":_vm.form?.contratante?.flagParticipanteConta && !_vm.ehProgramar && !_vm.ehConfirmar,"disabled":_vm.ehVisualizar || _vm.ehProgramar || _vm.ehConfirmar || _vm.ehExecutar},model:{value:(_vm.form.dataSugerida1.dataFinal),callback:function ($$v) {_vm.$set(_vm.form.dataSugerida1, "dataFinal", $$v)},expression:"form.dataSugerida1.dataFinal"}}),_c('input-time',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.horario_inicial'),"obrigatorio":_vm.form?.contratante?.flagParticipanteConta && !_vm.ehProgramar && !_vm.ehConfirmar,"disabled":_vm.ehVisualizar || _vm.ehProgramar || _vm.ehConfirmar || _vm.ehExecutar},model:{value:(_vm.form.dataSugerida1.horaInicial),callback:function ($$v) {_vm.$set(_vm.form.dataSugerida1, "horaInicial", $$v)},expression:"form.dataSugerida1.horaInicial"}}),_c('input-time',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.horario_final'),"obrigatorio":_vm.form?.contratante?.flagParticipanteConta && !_vm.ehProgramar && !_vm.ehConfirmar,"disabled":_vm.ehVisualizar || _vm.ehProgramar || _vm.ehConfirmar || _vm.ehExecutar},model:{value:(_vm.form.dataSugerida1.horaFinal),callback:function ($$v) {_vm.$set(_vm.form.dataSugerida1, "horaFinal", $$v)},expression:"form.dataSugerida1.horaFinal"}})],1)]),_c('accordion-padrao',{staticClass:"accordion-padrao col-12 col-md-4",attrs:{"alterar-cor":true,"cor-azul-texto":"","titulo":_vm.$t('modulos.agendamento.formulario.dados.sugestao_2')}},[_c('div',{staticClass:"row"},[_c('input-date',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.data_inicial'),"data-minima":_vm.participanteLogadoConta
                ? new Date().toISOString().slice(0, 10)
                : _vm.dataAgendamentoMinimoParaClientes,"disabled":!_vm.form.dataSugerida1.dataInicial ||
                _vm.ehVisualizar ||
                _vm.ehProgramar ||
                _vm.ehConfirmar ||
                _vm.ehExecutar ||
              _vm.form?.contratante?.flagParticipanteConta},model:{value:(_vm.form.dataSugerida2.dataInicial),callback:function ($$v) {_vm.$set(_vm.form.dataSugerida2, "dataInicial", $$v)},expression:"form.dataSugerida2.dataInicial"}}),_c('input-date',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.data_final'),"data-minima":_vm.participanteLogadoConta
                ? new Date().toISOString().slice(0, 10)
                : _vm.dataAgendamentoMinimoParaClientes,"disabled":!_vm.form.dataSugerida1.dataFinal ||
                _vm.ehVisualizar ||
                _vm.ehProgramar ||
                _vm.ehConfirmar ||
                _vm.ehExecutar ||
              _vm.form?.contratante?.flagParticipanteConta},model:{value:(_vm.form.dataSugerida2.dataFinal),callback:function ($$v) {_vm.$set(_vm.form.dataSugerida2, "dataFinal", $$v)},expression:"form.dataSugerida2.dataFinal"}}),_c('input-time',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.horario_inicial'),"disabled":!_vm.form.dataSugerida1.horaInicial ||
                _vm.ehVisualizar ||
                _vm.ehProgramar ||
                _vm.ehConfirmar ||
                _vm.ehExecutar ||
              _vm.form?.contratante?.flagParticipanteConta},model:{value:(_vm.form.dataSugerida2.horaInicial),callback:function ($$v) {_vm.$set(_vm.form.dataSugerida2, "horaInicial", $$v)},expression:"form.dataSugerida2.horaInicial"}}),_c('input-time',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.horario_final'),"disabled":!_vm.form.dataSugerida1.horaFinal ||
                _vm.ehVisualizar ||
                _vm.ehProgramar ||
                _vm.ehConfirmar ||
                _vm.ehExecutar ||
              _vm.form?.contratante?.flagParticipanteConta},model:{value:(_vm.form.dataSugerida2.horaFinal),callback:function ($$v) {_vm.$set(_vm.form.dataSugerida2, "horaFinal", $$v)},expression:"form.dataSugerida2.horaFinal"}})],1)]),_c('accordion-padrao',{staticClass:"accordion-padrao col-12 col-md-4",attrs:{"alterar-cor":true,"cor-azul-texto":"","titulo":_vm.$t('modulos.agendamento.formulario.dados.sugestao_3')}},[_c('div',{staticClass:"row"},[_c('input-date',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.data_inicial'),"data-minima":_vm.participanteLogadoConta
                ? new Date().toISOString().slice(0, 10)
                : _vm.dataAgendamentoMinimoParaClientes,"disabled":!_vm.form.dataSugerida2.dataInicial ||
                _vm.ehVisualizar ||
                _vm.ehProgramar ||
                _vm.ehConfirmar ||
                _vm.ehExecutar ||
              _vm.form?.contratante?.flagParticipanteConta},model:{value:(_vm.form.dataSugerida3.dataInicial),callback:function ($$v) {_vm.$set(_vm.form.dataSugerida3, "dataInicial", $$v)},expression:"form.dataSugerida3.dataInicial"}}),_c('input-date',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.data_final'),"data-minima":_vm.participanteLogadoConta
                ? new Date().toISOString().slice(0, 10)
                : _vm.dataAgendamentoMinimoParaClientes,"disabled":!_vm.form.dataSugerida2.dataFinal ||
                _vm.ehVisualizar ||
                _vm.ehProgramar ||
                _vm.ehConfirmar ||
                _vm.ehExecutar ||
              _vm.form?.contratante?.flagParticipanteConta},model:{value:(_vm.form.dataSugerida3.dataFinal),callback:function ($$v) {_vm.$set(_vm.form.dataSugerida3, "dataFinal", $$v)},expression:"form.dataSugerida3.dataFinal"}}),_c('input-time',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.horario_inicial'),"disabled":!_vm.form.dataSugerida2.horaInicial ||
                _vm.ehVisualizar ||
                _vm.ehProgramar ||
                _vm.ehConfirmar ||
                _vm.ehExecutar ||
              _vm.form?.contratante?.flagParticipanteConta},model:{value:(_vm.form.dataSugerida3.horaInicial),callback:function ($$v) {_vm.$set(_vm.form.dataSugerida3, "horaInicial", $$v)},expression:"form.dataSugerida3.horaInicial"}}),_c('input-time',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.horario_final'),"disabled":!_vm.form.dataSugerida2.horaFinal ||
                _vm.ehVisualizar ||
                _vm.ehProgramar ||
                _vm.ehConfirmar ||
                _vm.ehExecutar ||
              _vm.form?.contratante?.flagParticipanteConta},model:{value:(_vm.form.dataSugerida3.horaFinal),callback:function ($$v) {_vm.$set(_vm.form.dataSugerida3, "horaFinal", $$v)},expression:"form.dataSugerida3.horaFinal"}})],1)]),(_vm.ehProgramar || _vm.ehConfirmar || _vm.ehExecutar || _vm.ehVisualizar)?_c('accordion-padrao',{staticClass:"accordion-padrao col-12",attrs:{"alterar-cor":true,"cor-azul-texto":"","titulo":'Programação do Agendamento'}},[_c('div',{staticClass:"row"},[_c('input-date',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.data_inicial'),"apenas-datas-futuras":"","obrigatorio":"","disabled":_vm.ehVisualizar || _vm.ehExecutar || _vm.ehVisualizar},model:{value:(_vm.form.dataInicial),callback:function ($$v) {_vm.$set(_vm.form, "dataInicial", $$v)},expression:"form.dataInicial"}}),_c('input-time',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.horario_inicial'),"obrigatorio":"","disabled":_vm.ehVisualizar || _vm.ehExecutar || _vm.ehVisualizar},model:{value:(_vm.form.horaInicial),callback:function ($$v) {_vm.$set(_vm.form, "horaInicial", $$v)},expression:"form.horaInicial"}}),_c('input-date',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.data_final'),"apenas-datas-futuras":"","obrigatorio":"","disabled":_vm.ehVisualizar || _vm.ehExecutar || _vm.ehVisualizar},model:{value:(_vm.form.dataFinal),callback:function ($$v) {_vm.$set(_vm.form, "dataFinal", $$v)},expression:"form.dataFinal"}}),_c('input-time',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.horario_final'),"obrigatorio":"","disabled":_vm.ehVisualizar || _vm.ehExecutar || _vm.ehVisualizar},model:{value:(_vm.form.horaFinal),callback:function ($$v) {_vm.$set(_vm.form, "horaFinal", $$v)},expression:"form.horaFinal"}})],1)]):_vm._e(),(
          _vm.form?.contratante?.flagParticipanteConta ||
          _vm.ehProgramar ||
          _vm.ehConfirmar ||
          _vm.ehExecutar
        )?_c('input-colaborador-agendamento',{staticClass:"col-12 col-md-4",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.colaboradores'),"placeholder":_vm.$t('geral.inputs.selecione'),"multiplos":true,"participante-cliente-id":_vm.participanteIdInputColaborador,"data-hora-agendamento-inicial":_vm.colaboradorDataHoraAgendamentoInicial,"data-hora-agendamento-final":_vm.colaboradorDataHoraAgendamentoFinal,"disabled":_vm.liberarInputsParticipanteConta || _vm.ehExecutar,"obrigatorio":_vm.ehProgramar},model:{value:(_vm.form.colaboradores),callback:function ($$v) {_vm.$set(_vm.form, "colaboradores", $$v)},expression:"form.colaboradores"}}):_vm._e(),(
          _vm.form?.contratante?.flagParticipanteConta ||
          _vm.ehProgramar ||
          _vm.ehConfirmar ||
          _vm.ehExecutar
        )?_c('input-instrumentos-agendamento',{staticClass:"col-12 col-md-4",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.instrumento_padrao'),"placeholder":_vm.$t('geral.inputs.selecione'),"multiplos":true,"somente-ativos":"","instrumento-padrao":"","cliente-selecionado":_vm.form?.contratante,"data-hora-agendamento-inicial":_vm.colaboradorDataHoraAgendamentoInicial,"data-hora-agendamento-final":_vm.colaboradorDataHoraAgendamentoFinal,"disabled":_vm.liberarInputsParticipanteConta || _vm.ehExecutar,"pode-emprestar":_vm.ehProgramar,"obrigatorio":_vm.ehProgramar},model:{value:(_vm.form.instrumentoPadrao),callback:function ($$v) {_vm.$set(_vm.form, "instrumentoPadrao", $$v)},expression:"form.instrumentoPadrao"}}):_vm._e(),(
          _vm.form?.contratante?.flagParticipanteConta ||
          _vm.ehProgramar ||
          _vm.ehConfirmar ||
          _vm.ehExecutar
        )?_c('input-veiculo-agendamento',{staticClass:"col-12 col-md-4",attrs:{"multiplos":false,"label":_vm.$t('modulos.agendamento.formulario.dados.veiculo'),"data-hora-agendamento-inicial":_vm.colaboradorDataHoraAgendamentoInicial,"data-hora-agendamento-final":_vm.colaboradorDataHoraAgendamentoFinal,"disabled":_vm.liberarInputsParticipanteConta || _vm.ehExecutar,"participante-id":_vm.participanteIdInputColaborador,"obrigatorio":_vm.ehProgramar},model:{value:(_vm.form.veiculo),callback:function ($$v) {_vm.$set(_vm.form, "veiculo", $$v)},expression:"form.veiculo"}}):_vm._e(),_c('input-textarea',{staticClass:"col-12",attrs:{"label":_vm.$t('modulos.agendamento.formulario.dados.observacao'),"max":5000,"disabled":_vm.ehVisualizar || _vm.ehExecutar}})],1)],1),(_vm.ehVisualizar)?_c('v-divider',{staticClass:"my-5"}):_vm._e(),_c('visualizar-historico',{attrs:{"eh-visualizar":_vm.ehVisualizar,"form":_vm.form}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }