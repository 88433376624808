<template>
  <div class="mt-3">
    <v-form
      ref="form"
      class="row"
    >
      <input-participantes
        v-model="form.contratante"
        class="col-12 col-md-3"
        :label="$t('modulos.agendamento.formulario.dados.contratante')"
        :titulo="$t('modulos.agendamento.formulario.dados.contratante')"
        :multiplos="false"
        :disabled="
          !participanteLogadoConta ||
            ehVisualizar ||
            ehProgramar ||
            ehConfirmar ||
            ehExecutar
        "
        obrigatorio
      />
      <input-select
        v-model="form.contatoContratante"
        :options="opcoes.contato"
        class="col-12 col-md-2"
        :label="$t('modulos.agendamento.formulario.dados.contato')"
        :placeholder="$t('geral.inputs.selecione')"
        obrigatorio
        retornar-objeto
        :disabled="ehVisualizar || ehProgramar || ehConfirmar || ehExecutar"
      />
      <input-cliente
        v-model="form.solicitante"
        class="col-12 col-md-3"
        :label="$t('modulos.agendamento.formulario.dados.solicitante')"
        :multiplos="false"
        :disabled="
          !participanteLogadoConta ||
            clienteNaoEhLaboratorio ||
            ehVisualizar ||
            ehProgramar ||
            ehConfirmar ||
            ehExecutar
        "
        obrigatorio
      />
      <input-select
        v-model="form.tipoVisita"
        :options="tipoVisitaFiltrada"
        class="col-12 col-md-2"
        :label="$t('modulos.agendamento.formulario.dados.tipo_visita')"
        :placeholder="$t('geral.inputs.selecione')"
        retornar-objeto
        obrigatorio
        :disabled="ehVisualizar || ehConfirmar || ehExecutar"
      />
      <input-select
        v-model="form.prioridade"
        :options="opcoes.prioridade"
        :label="$t('modulos.agendamento.formulario.dados.prioridade')"
        :placeholder="$t('geral.inputs.selecione')"
        class="col-12 col-md-2"
        retornar-objeto
        clearable
        :disabled="ehVisualizar || ehExecutar"
      />

      <div class="row col-12">
        <accordion-padrao
          :alterar-cor="true"
          cor-azul-texto
          :titulo="$t('modulos.agendamento.formulario.dados.sugestao_1')"
          class="accordion-padrao col-12 col-md-4"
          :disabled="ehVisualizar || ehProgramar || ehConfirmar || ehExecutar"
        >
          <div class="row">
            <input-date
              v-model="form.dataSugerida1.dataInicial"
              class="col-12 col-md-6"
              :label="$t('modulos.agendamento.formulario.dados.data_inicial')"
              :data-minima="
                participanteLogadoConta
                  ? new Date().toISOString().slice(0, 10)
                  : dataAgendamentoMinimoParaClientes
              "
              :obrigatorio="form?.contratante?.flagParticipanteConta && !ehProgramar && !ehConfirmar"
              :disabled="
                ehVisualizar || ehProgramar || ehConfirmar || ehExecutar
              "
            />
            <input-date
              v-model="form.dataSugerida1.dataFinal"
              class="col-12 col-md-6"
              :label="$t('modulos.agendamento.formulario.dados.data_final')"
              :data-minima="
                participanteLogadoConta
                  ? new Date().toISOString().slice(0, 10)
                  : dataAgendamentoMinimoParaClientes
              "
              :obrigatorio="form?.contratante?.flagParticipanteConta && !ehProgramar && !ehConfirmar"
              :disabled="
                ehVisualizar || ehProgramar || ehConfirmar || ehExecutar
              "
            />
            <input-time
              v-model="form.dataSugerida1.horaInicial"
              class="col-12 col-md-6"
              :label="
                $t('modulos.agendamento.formulario.dados.horario_inicial')
              "
              :obrigatorio="form?.contratante?.flagParticipanteConta && !ehProgramar && !ehConfirmar"
              :disabled="
                ehVisualizar || ehProgramar || ehConfirmar || ehExecutar
              "
            />
            <input-time
              v-model="form.dataSugerida1.horaFinal"
              class="col-12 col-md-6"
              :label="$t('modulos.agendamento.formulario.dados.horario_final')"
              :obrigatorio="form?.contratante?.flagParticipanteConta && !ehProgramar && !ehConfirmar"
              :disabled="
                ehVisualizar || ehProgramar || ehConfirmar || ehExecutar
              "
            />
          </div>
        </accordion-padrao>

        <accordion-padrao
          :alterar-cor="true"
          cor-azul-texto
          :titulo="$t('modulos.agendamento.formulario.dados.sugestao_2')"
          class="accordion-padrao col-12 col-md-4"
        >
          <div class="row">
            <input-date
              v-model="form.dataSugerida2.dataInicial"
              class="col-12 col-md-6"
              :label="$t('modulos.agendamento.formulario.dados.data_inicial')"
              :data-minima="
                participanteLogadoConta
                  ? new Date().toISOString().slice(0, 10)
                  : dataAgendamentoMinimoParaClientes
              "
              :disabled="
                !form.dataSugerida1.dataInicial ||
                  ehVisualizar ||
                  ehProgramar ||
                  ehConfirmar ||
                  ehExecutar ||
                form?.contratante?.flagParticipanteConta
              "
            />
            <input-date
              v-model="form.dataSugerida2.dataFinal"
              class="col-12 col-md-6"
              :label="$t('modulos.agendamento.formulario.dados.data_final')"
              :data-minima="
                participanteLogadoConta
                  ? new Date().toISOString().slice(0, 10)
                  : dataAgendamentoMinimoParaClientes
              "
              :disabled="
                !form.dataSugerida1.dataFinal ||
                  ehVisualizar ||
                  ehProgramar ||
                  ehConfirmar ||
                  ehExecutar ||
                form?.contratante?.flagParticipanteConta
              "
            />
            <input-time
              v-model="form.dataSugerida2.horaInicial"
              class="col-12 col-md-6"
              :label="
                $t('modulos.agendamento.formulario.dados.horario_inicial')
              "
              :disabled="
                !form.dataSugerida1.horaInicial ||
                  ehVisualizar ||
                  ehProgramar ||
                  ehConfirmar ||
                  ehExecutar ||
                form?.contratante?.flagParticipanteConta
              "
            />
            <input-time
              v-model="form.dataSugerida2.horaFinal"
              class="col-12 col-md-6"
              :label="$t('modulos.agendamento.formulario.dados.horario_final')"
              :disabled="
                !form.dataSugerida1.horaFinal ||
                  ehVisualizar ||
                  ehProgramar ||
                  ehConfirmar ||
                  ehExecutar ||
                form?.contratante?.flagParticipanteConta
              "
            />
          </div>
        </accordion-padrao>
        <accordion-padrao
          :alterar-cor="true"
          cor-azul-texto
          :titulo="$t('modulos.agendamento.formulario.dados.sugestao_3')"
          class="accordion-padrao col-12 col-md-4"
        >
          <div class="row">
            <input-date
              v-model="form.dataSugerida3.dataInicial"
              class="col-12 col-md-6"
              :label="$t('modulos.agendamento.formulario.dados.data_inicial')"
              :data-minima="
                participanteLogadoConta
                  ? new Date().toISOString().slice(0, 10)
                  : dataAgendamentoMinimoParaClientes
              "
              :disabled="
                !form.dataSugerida2.dataInicial ||
                  ehVisualizar ||
                  ehProgramar ||
                  ehConfirmar ||
                  ehExecutar ||
                form?.contratante?.flagParticipanteConta
              "
            />
            <input-date
              v-model="form.dataSugerida3.dataFinal"
              class="col-12 col-md-6"
              :label="$t('modulos.agendamento.formulario.dados.data_final')"
              :data-minima="
                participanteLogadoConta
                  ? new Date().toISOString().slice(0, 10)
                  : dataAgendamentoMinimoParaClientes
              "
              :disabled="
                !form.dataSugerida2.dataFinal ||
                  ehVisualizar ||
                  ehProgramar ||
                  ehConfirmar ||
                  ehExecutar ||
                form?.contratante?.flagParticipanteConta
              "
            />
            <input-time
              v-model="form.dataSugerida3.horaInicial"
              class="col-12 col-md-6"
              :label="
                $t('modulos.agendamento.formulario.dados.horario_inicial')
              "
              :disabled="
                !form.dataSugerida2.horaInicial ||
                  ehVisualizar ||
                  ehProgramar ||
                  ehConfirmar ||
                  ehExecutar ||
                form?.contratante?.flagParticipanteConta
              "
            />
            <input-time
              v-model="form.dataSugerida3.horaFinal"
              class="col-12 col-md-6"
              :label="$t('modulos.agendamento.formulario.dados.horario_final')"
              :disabled="
                !form.dataSugerida2.horaFinal ||
                  ehVisualizar ||
                  ehProgramar ||
                  ehConfirmar ||
                  ehExecutar ||
                form?.contratante?.flagParticipanteConta
              "
            />
          </div>
        </accordion-padrao>
        <accordion-padrao
          v-if="ehProgramar || ehConfirmar || ehExecutar || ehVisualizar"
          :alterar-cor="true"
          cor-azul-texto
          :titulo="'Programação do Agendamento'"
          class="accordion-padrao col-12"
        >
          <div class="row">
            <input-date
              v-model="form.dataInicial"
              class="col-12 col-md-3"
              :label="$t('modulos.agendamento.formulario.dados.data_inicial')"
              apenas-datas-futuras
              obrigatorio
              :disabled="ehVisualizar || ehExecutar || ehVisualizar"
            />

            <input-time
              v-model="form.horaInicial"
              class="col-12 col-md-3"
              :label="
                $t('modulos.agendamento.formulario.dados.horario_inicial')
              "
              obrigatorio
              :disabled="ehVisualizar || ehExecutar || ehVisualizar"
            />
            <input-date
              v-model="form.dataFinal"
              class="col-12 col-md-3"
              :label="$t('modulos.agendamento.formulario.dados.data_final')"
              apenas-datas-futuras
              obrigatorio
              :disabled="ehVisualizar || ehExecutar || ehVisualizar"
            />
            <input-time
              v-model="form.horaFinal"
              class="col-12 col-md-3"
              :label="$t('modulos.agendamento.formulario.dados.horario_final')"
              obrigatorio
              :disabled="ehVisualizar || ehExecutar || ehVisualizar"
            />
          </div>
        </accordion-padrao>
        <input-colaborador-agendamento
          v-if="
            form?.contratante?.flagParticipanteConta ||
            ehProgramar ||
            ehConfirmar ||
            ehExecutar
          "
          v-model="form.colaboradores"
          class="col-12 col-md-4"
          :label="$t('modulos.agendamento.formulario.dados.colaboradores')"
          :placeholder="$t('geral.inputs.selecione')"
          :multiplos="true"
          :participante-cliente-id="participanteIdInputColaborador"
          :data-hora-agendamento-inicial="colaboradorDataHoraAgendamentoInicial"
          :data-hora-agendamento-final="colaboradorDataHoraAgendamentoFinal"
          :disabled="liberarInputsParticipanteConta || ehExecutar"
          :obrigatorio="ehProgramar"
        />

        <input-instrumentos-agendamento
          v-if="
            form?.contratante?.flagParticipanteConta ||
            ehProgramar ||
            ehConfirmar ||
            ehExecutar
          "
          v-model="form.instrumentoPadrao"
          class="col-12 col-md-4"
          :label="$t('modulos.agendamento.formulario.dados.instrumento_padrao')"
          :placeholder="$t('geral.inputs.selecione')"
          :multiplos="true"
          somente-ativos
          instrumento-padrao
          :cliente-selecionado="form?.contratante"
          :data-hora-agendamento-inicial="colaboradorDataHoraAgendamentoInicial"
          :data-hora-agendamento-final="colaboradorDataHoraAgendamentoFinal"
          :disabled="liberarInputsParticipanteConta || ehExecutar"
          :pode-emprestar="ehProgramar"
          :obrigatorio="ehProgramar"
        />
        <input-veiculo-agendamento
          v-if="
            form?.contratante?.flagParticipanteConta ||
            ehProgramar ||
            ehConfirmar ||
            ehExecutar
          "
          v-model="form.veiculo"
          :multiplos="false"
          class="col-12 col-md-4"
          :label="$t('modulos.agendamento.formulario.dados.veiculo')"
          :data-hora-agendamento-inicial="colaboradorDataHoraAgendamentoInicial"
          :data-hora-agendamento-final="colaboradorDataHoraAgendamentoFinal"
          :disabled="liberarInputsParticipanteConta || ehExecutar"
          :participante-id="participanteIdInputColaborador"
          :obrigatorio="ehProgramar"
        />
        <input-textarea
          class="col-12"
          :label="$t('modulos.agendamento.formulario.dados.observacao')"
          :max="5000"
          :disabled="ehVisualizar || ehExecutar"
        />
      </div>
    </v-form>
    <v-divider
      v-if="ehVisualizar"
      class="my-5"
    />
    <visualizar-historico
      :eh-visualizar="ehVisualizar"
      :form="form"
    />
  </div>
</template>

<script>
import {
  InputParticipantes,
  InputCliente,
  InputColaboradorAgendamento,
  InputInstrumentosAgendamento,
  InputVeiculoAgendamento,
} from '@/components/inputs';
import { mapGetters } from 'vuex';
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import moment from 'moment';
import { MetodosBuscar } from '../mixins';
import { isEqual } from 'lodash';
import VisualizarHistorico from './VisualizarHistorico.vue';
export default {
  components: {
    InputParticipantes,
    InputCliente,
    InputColaboradorAgendamento,
    InputInstrumentosAgendamento,
    InputVeiculoAgendamento,
    VisualizarHistorico,
  },
  mixins: [MetodosBuscar],
  props: ['form', 'ehVisualizar', 'ehProgramar', 'ehConfirmar', 'ehExecutar'],
  data() {
    return {
      clienteNaoEhLaboratorio: false,
      prazoAgendamentoClientes: 0,
      dataAgendamentoMinimoParaClientes: null,
      opcoes: {
        contato: [],
        tipoVisita: [],
        prioridade: [],
      },
      watchers: {
        contratante: null,
        dataSugerida1_DataInicial: null,
        dataSugerida2_DataInicial: null,
        dataSugerida3_DataInicial: null,
        dataProgramacao_DataInicial: null,
        dataSugerida1_HoraInicial: null,
        dataSugerida2_HoraInicial: null,
        dataSugerida3_HoraInicial: null,
        dataProgramacao_HoraInicial: null,
      },
      feriados: [],
    };
  },
  computed: {
    ...mapGetters('Autenticacao', [
      'participanteLogadoConta',
      'participanteSelecionado',
    ]),
    tipoVisitaFiltrada() {
      if (this.form?.contratante?.flagParticipanteConta)
        return new DropdownModel(this.opcoes.tipoVisita);
      return new DropdownModel(
        this.opcoes.tipoVisita.filter(
          (visita) => visita.acessivelClientes == true
        )
      );
    },
    colaboradorDataHoraAgendamentoInicial() {
      if (this.ehProgramar) {
        if (this.form?.dataInicial && this.form?.horaInicial)
          return (
            `${this.form?.dataInicial.substring(0, 11)}` +
            `${this.form?.horaInicial}` +
            ':00.000Z'
          );
        return null;
      }
      if (
        this.form?.dataSugerida1?.dataInicial &&
        this.form?.dataSugerida1?.horaInicial
      )
        return (
          `${this.form?.dataSugerida1?.dataInicial.substring(0, 11)}` +
          `${this.form?.dataSugerida1?.horaInicial}` +
          ':00.000Z'
        );
      return null;
    },
    colaboradorDataHoraAgendamentoFinal() {
      if (this.ehProgramar) {
        if (this.form?.dataFinal && this.form?.horaFinal)
          return (
            `${this.form?.dataFinal.substring(0, 11)}` +
            `${this.form?.horaFinal}` +
            ':00.000Z'
          );
        return null;
      }
      if (
        this.form?.dataSugerida1?.dataFinal &&
        this.form?.dataSugerida1?.horaFinal
      )
        return (
          `${this.form?.dataSugerida1?.dataFinal.substring(0, 11)}` +
          `${this.form?.dataSugerida1?.horaFinal}` +
          ':00.000Z'
        );
      return null;
    },
    liberarInputsParticipanteConta() {
      return (
        this.ehVisualizar ||
        this.ehConfirmar ||
        (!this.ehProgramar &&
          (!this.form?.dataSugerida1?.dataInicial ||
            !this.form?.dataSugerida1?.dataFinal ||
            !this.form?.dataSugerida1?.horaInicial ||
            !this.form?.dataSugerida1?.horaFinal))
      );
    },
    participanteIdInputColaborador() {
      if (this.ehProgramar)
        return this.$store.getters['Autenticacao/participanteSelecionado'];

      return this.form?.contratante?.value;
    },
  },
  async mounted() {
    await Promise.all([
      this.buscarPrioridades(),
      this.buscarTipoVisita(),
      this.buscarParametrosDaConta(),
      this.buscarFeriados(),
    ]);

    //SO EXECUTAR SE NAO TIVER O BUSCAR
    if (!this.participanteLogadoConta) {
      await this.buscarParticipanteLogado(this.participanteSelecionado);
    }
  },
  methods: {
    definirWatchers() {
      this.watchContratante();
      this.watchDataSugerida();
      this.$refs.form.resetValidation();
    },
    watchContratante: function () {
      if (this.watchers.contratante) this.watchers.contratante();

      this.watchers.contratante = this.$watch(
        'form.contratante',
        (newValue, oldValue) => {
          if (isEqual(newValue, oldValue)) return;
          if (newValue?.flagParticipanteConta) {
            this.toastAlerta(
              this.$t(
                'modulos.agendamento.formulario.dados.mensagens.contratante_participante_conta'
              ),
              'warning'
            );
          }
          if (newValue?.value) {
            this.form.solicitante = newValue;
            this.buscarContatoParticipante(newValue.value);
          }

          if (this.form.tipoVisita) this.form.tipoVisita = null;
          if (this.form.colaboradores) this.form.colaboradores = null;
          if (this.form.instrumentoPadrao) this.form.instrumentoPadrao = null;
          if (this.form.veiculo) this.form.veiculo = null;
          this.form.instrumentos = [];
        },
        { deep: true }
      );
    },
    watchDataSugerida: function () {
      if (this.watchers.dataSugerida1_DataInicial)
        this.watchers.dataSugerida1_DataInicial();
      if (this.watchers.dataSugerida2_DataInicial)
        this.watchers.dataSugerida2_DataInicial();
      if (this.watchers.dataSugerida3_DataInicial)
        this.watchers.dataSugerida3_DataInicial();
      if (this.watchers.dataProgramacao_DataInicial)
        this.watchers.dataProgramacao_DataInicial();
      if (this.watchers.dataSugerida1_HoraInicial)
        this.watchers.dataSugerida1_HoraInicial();
      if (this.watchers.dataSugerida2_HoraInicial)
        this.watchers.dataSugerida2_HoraInicial();
      if (this.watchers.dataSugerida3_HoraInicial)
        this.watchers.dataSugerida3_HoraInicial();
      if (this.watchers.dataProgramacao_HoraInicial)
        this.watchers.dataProgramacao_HoraInicial();

      this.watchers.dataSugerida1_DataInicial = this.$watch(
        'form.dataSugerida1.dataInicial',
        (newValue) => {
          if (newValue) {
            this.form.dataSugerida1.dataFinal = newValue;
            console.log(newValue)
          }
        }
      );
      this.watchers.dataSugerida1_HoraInicial = this.$watch(
        'form.dataSugerida1.horaInicial',
        (newValue) => {
          if (newValue) {
            this.form.dataSugerida1.horaFinal = this.adicionarHora(newValue);
          }
        }
      );
      this.watchers.dataSugerida2_DataInicial = this.$watch(
        'form.dataSugerida2.dataInicial',
        (newValue) => {
          if (newValue) {
            this.form.dataSugerida2.dataFinal = newValue;
          }
        }
      );
      this.watchers.dataSugerida2_HoraInicial = this.$watch(
        'form.dataSugerida2.horaInicial',
        (newValue) => {
          if (newValue) {
            this.form.dataSugerida2.horaFinal = this.adicionarHora(newValue);
          }
        }
      );
      this.watchers.dataSugerida3_DataInicial = this.$watch(
        'form.dataSugerida3.dataInicial',
        (newValue) => {
          if (newValue) {
            this.form.dataSugerida3.dataFinal = newValue;
          }
        }
      );
      this.watchers.dataSugerida3_HoraInicial = this.$watch(
        'form.dataSugerida3.horaInicial',
        (newValue) => {
          if (newValue) {
            this.form.dataSugerida3.horaFinal = this.adicionarHora(newValue);
          }
        }
      );
      this.watchers.dataProgramacao_DataInicial = this.$watch(
        'form.dataInicial',
        (newValue) => {
          if (newValue) {
            this.form.dataFinal = newValue;
          }
        }
      );
      this.watchers.dataProgramacao_HoraInicial = this.$watch(
        'form.horaInicial',
        (newValue) => {
          if (newValue) {
            this.form.horaFinal = this.adicionarHora(newValue);
          }
        }
      );
    },

    salvarDados: function () {
      const valido = this.$refs.form.validate();
      if (!valido) return { dadosValido: false, temFimDeSemanaOuFeriado: null };

      const datas = [
        this.form?.dataSugerida1.dataInicial,
        this.form?.dataSugerida1.dataFinal,
        this.form?.dataSugerida2.dataInicial,
        this.form?.dataSugerida2.dataFinal,
        this.form?.dataSugerida3.dataInicial,
        this.form?.dataSugerida3.dataFinal,
      ];

      const temFimDeSemana = datas.some((data) => {
        if (data) {
          const dia = new Date(data).getDay();
          return dia == 0 || dia == 6;
        }
      });

      const temFeriado = datas.some((data) => {
        return this.feriados.some((feriado) => {
          return moment(feriado, 'YYYY-MM-DD').isSame(
            moment(data, 'YYYY-MM-DD')
          );
        });
      });

      return {
        dadosValido: true,
        temFimDeSemanaOuFeriado: temFimDeSemana || temFeriado,
      };
    },
    adicionarHora: function (h) {
      let hora = moment(h, 'HH:mm');
      return hora.add(1, 'hours').format('HH:mm');
    },
  },
};
</script>

<style scoped></style>
