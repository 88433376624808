<template lang="">
  <div
    v-if="ehVisualizar"
    class="row"
  >
    <input-date
      :value="form.dataInicial"
      :label="$t('modulos.agendamento.formulario.dados.data_inicial')"
      class="col-12 col-md-3"
      disabled
    />
    <input-date
      :value="form.dataFinal"
      :label="$t('modulos.agendamento.formulario.dados.data_final')"
      class="col-12 col-md-3"
      disabled
    />
    <input-time
      :value="form.horaInicial"
      :label="$t('modulos.agendamento.formulario.dados.horario_inicial')"
      class="col-12 col-md-3"
      disabled
    />
    <input-time
      :value="form.horaFinal"
      :label="$t('modulos.agendamento.formulario.dados.horario_final')"
      class="col-12 col-md-3"
      disabled
    />
    <input-text
      :value="form.checkin"
      :label="$t('modulos.agendamento.formulario.dados.check_in')"
      class="col-12 col-md-4"
      disabled
    />
    <input-text
      :value="form.atendimento"
      :label="$t('modulos.agendamento.formulario.dados.atendimento')"
      class="col-12 col-md-4"
      disabled
    />
    <input-text
      :value="form.checkout"
      :label="$t('modulos.agendamento.formulario.dados.check_out')"
      class="col-12 col-md-4"
      disabled
    />
    <div
      class="EnvioMaterialHistorico"
      style="position: relative; padding: 25px; width: 100%"
    >
      <v-stepper vertical>
        <template v-for="(item, index) in form.historico">
          <v-stepper-step
            :key="item.id"
            complete
            :step="`${index + 1}`"
            :complete-icon="tiposHistorico[`${item.situacao}`].icone"
            :color="tiposHistorico[`${item.situacao}`].cor"
          >
            <div
              v-if="checarSituacao(item.situacao)"
              class="historico-envio"
            >
              <span>{{ item.dataOcorrencia | formatarDataHora }}</span>
              <div class="d-flex flex-column">
                <span>
                  <span>
                    {{ $t('modulos.agendamento.historico.texto.agendamento') }}
                  </span>
                  <b>{{ tiposHistorico[`${item.situacao}`].enum }}</b>
                  {{ $t('modulos.agendamento.historico.texto.por') }}
                  <b>{{ item.usuario.nome }}</b></span>
                <span>
                  <!-- <b>{{ item.dataOcorrencia | formatarData }}</b> - -->
                  {{ $t('modulos.agendamento.historico.texto.participante') }}:
                  <b>{{ item.usuario.nome }}</b></span>
              </div>
            </div>
            <div
              v-else
              class="historico-envio"
            >
              <span>{{ item.dataOcorrencia | formatarDataHora }}</span>
              <div class="d-flex flex-column">
                <span>
                  <b>{{ tiposHistorico[`${item.situacao}`].enum }}</b>
                  {{ $t('modulos.agendamento.historico.texto.no_cliente') }}
                  <b>{{ item.usuario.nome }}</b></span>
                <span>{{ $t('modulos.agendamento.historico.texto.agendamento') }}:
                  <b>{{ item.dataOcorrencia | formatarData }}</b> -
                  {{ $t('modulos.agendamento.historico.texto.participante') }}:
                  <b>{{ item.usuario.nome }}</b></span>
              </div>
            </div>
          </v-stepper-step>
          <v-stepper-content
            :key="`${item.id} ${index}`"
            :step="`${index + 1}`"
          />
        </template>
      </v-stepper>
    </div>
  </div>
</template>
<script>
import helpers from '@/common/utils/helpers';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
export default {
  filters: {
    formatarDataHora: function (data) {
      return helpers.formatarDataBr(data, true);
    },
    formatarData: function (data) {
      return helpers.formatarDataBr(data);
    },
  },
  props: {
    ehVisualizar: {
      type: Boolean,
      default: false,
    },
    historico: {
      type: Array,
      default: () => [],
    },
    situacao: {
      type: String,
      default: '',
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      opcoes: {
        EnumSituacaoEnvioMaterial: [],
      },
      tiposHistorico: {
        Criado: {
          icone: '$plus',
          cor: '#35507B',
          enum: this.$t('modulos.agendamento.historico.Criado'),
        },
        Programado: {
          icone: '$clockOutline',
          cor: '#D0D5DD',
          enum: this.$t('modulos.agendamento.historico.Programado'),
        },
        Alterado: {
          icone: '$mdiRefresh',
          cor: '#F79009',
          enum: this.$t('modulos.agendamento.historico.Alterado'),
        },
        Concluido: {
          icone: '$mdiCheck',
          cor: '#12B76A',
          enum: this.$t('modulos.agendamento.historico.Concluido'),
        },
        Checkin: {
          icone: '$playCircleOutline',
          cor: 'rgba(53, 80, 123, 0.70)',
          enum: this.$t('modulos.agendamento.historico.Checkin'),
        },
        Reagendado: {
          icone: '$fileCheckOutline',
          cor: 'rgba(53, 80, 123, 0.70)',
          enum: this.$t('modulos.agendamento.historico.Reagendado'),
        },
        Cancelado: {
          icone: '$close',
          cor: 'rgba(53, 80, 123, 0.70)',
          enum: this.$t('modulos.agendamento.historico.Cancelado'),
        },
        OrdemServicoCriada: {
          icone: '$plus',
          cor: '#35507B',
          enum: this.$t('modulos.agendamento.historico.OrdemServicoCriada'),
        },
        SemAgendamento: {
          icone: '$calendar',
          cor: '#F00038',
          enum: this.$t('modulos.agendamento.historico.Reagendado'),
        },
        CheckOut: {
          icone: '$playCircleOutline',
          cor: 'rgba(53, 80, 123, 0.70)',
          enum: this.$t('modulos.agendamento.historico.CheckOut'),
        },
        AtendimentoIniciado: {
          icone: '$playCircleOutline',
          cor: '#12D76A',
          enum: this.$t('modulos.agendamento.historico.AtendimentoIniciado'),
        },
        AtendimentoFinalizado: {
          icone: '$playCircleOutline',
          cor: '#12A76A',
          enum: this.$t('modulos.agendamento.historico.AtendimentoFinalizado'),
        },
      },
    };
  },

  async mounted() {
    await this.buscarEnumSituacaoEnvioMaterial();
  },
  methods: {
    checarSituacao: function (situacao) {
      if (
        situacao === 'Criado' ||
        situacao === 'Programado' ||
        situacao === 'Alterado' ||
        situacao === 'Reagendado' ||
        situacao === 'Concluido'
      ) {
        return true;
      } else {
        return false;
      }
    },
    buscarEnumSituacaoEnvioMaterial: async function () {
      await EnumeradorService.buscar('EnumSituacaoEnvioMaterial').then(
        (res) => {
          this.opcoes.EnumSituacaoEnvioMaterial = res;
        }
      );
    },
    situacaoEnum: function (value) {
      if (value)
        return this.opcoes.EnumSituacaoEnvioMaterial.find(
          (el) => el.value === value
        )?.text;

      return '';
    },
  },
};
</script>
<style lang="scss">
.EnvioMaterialHistorico {
  .historico-envio {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }
  .historico-envio + .historico-envio {
    margin-top: 40px;
  }

  @media (max-width: 768px) {
    .v-stepper__step__step {
      padding: 10px !important;
    }
  }

  .v-sheet.v-stepper {
    box-shadow: none !important;
  }

  .v-stepper__step {
    padding-left: 0 !important;
    padding-right: 0 !important;
    svg {
      width: 20px !important;
    }
  }

  .v-stepper__content {
    margin-left: 16px !important;
  }

  .v-stepper__step__step {
    padding: 18px;
  }
}
</style>
