<template>
  <div>
    <tabela-padrao-prime-vue
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      sem-paginacao
      :mostrar-acoes="false"
      :mostrar-seletor="false"
    >
      <template v-slot:cancelaReagenda="{ slotProps }">
        <div>
          <input-radio
            v-model="slotProps.data.cancelaReagenda"
            class="flex-fill input-tabela-campos-adicionais mb-4"
            :options="opcoes.cancelaReagenda"
            sem-label
          />
        </div>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>

<script>
import AgendamentoService from '@/common/services/cadastros/AgendamentoService.js';
export default {
  props: ['form', 'id'],

  data() {
    return {
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'instrumento.codigo',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.analise_critica.instrumento'
            ),
            sortable: false,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.analise_critica.tipo_instrumento'
            ),
            sortable: false,
          },
          {
            value: 'manutencaoPeca',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.analise_critica.manutencaoPecaCalibracao'
            ),
            sortable: false,
          },
          {
            value: 'codigoManutencaoPeca',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.analise_critica.codigo'
            ),
            sortable: false,
          },
          {
            value: 'nomeManutencaoPeca',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.analise_critica.nome'
            ),
            sortable: false,
          },
          {
            value: 'quantidade',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.analise_critica.quantidade'
            ),
            sortable: false,
          },
          {
            value: 'justificativa',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.analise_critica.justificativa'
            ),
            sortable: false,
          },
          {
            value: 'cancelaReagenda',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.analise_critica.cancelaReagenda'
            ),
            sortable: false,
          },
        ],
      },
      opcoes: {
        cancelaReagenda: [
          {
            value: false,
            text: this.$t(
              'modulos.agendamento.resumo.tabela.analise_critica.cancelar'
            ),
          },
          {
            value: true,
            text: this.$t(
              'modulos.agendamento.resumo.tabela.analise_critica.reagendar'
            ),
          },
        ],
      },
    };
  },

  mounted() {
    this.buscar(this.id);
  },
  methods: {
    buscar: function (id) {
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Buscando Análise Crítica'
      );
      AgendamentoService.buscarAnaliseCritica(id)
        .then((res) => {
          this.tabela.dados = res.data.map((item) => {
            return {
              ...item,
              cancelaReagenda: true,
            };
          });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    salvar: function () {
      const instrumentosReagendados = [];
      const instrumentosIds = new Set();
      this.tabela.dados.forEach((item) => {
        if (
          item.cancelaReagenda &&
          !instrumentosIds.has(item.instrumento.id)
        ) {
          instrumentosReagendados.push(item.instrumento.id);
          instrumentosIds.add(item.instrumento.id);
        }
      });

      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Salvando Análise Crítica'
      );
      AgendamentoService.analiseCritica(this.id, {
        instrumentosIds: instrumentosReagendados,
      })
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.agendamento.analise_critica_sucesso`)
          );
          this.$router.push({
            name: 'agendamento',
          });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>

<style scoped></style>
