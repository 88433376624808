<template lang="">
  <modal-padrao
    ref="modal-input"
    :max-width="1000"
    :titulo="$t('componentes.input_instrumento.titulo_modal_selecionar')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    @ok="aplicarSelecionados"
  >
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="selecionados"
      class="mt-2"
      style="min-height: 300px"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      filtros-com-colunas
      filtro-global-focus
      :mostrar-acoes="false"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :selecionar-apenas-um="!multiplos"
      ajustar-altura-linha
      @paginar="listar"
      @filtrarGlobal="filtrar"
      @filtroGlobal="(v) => (filtro.filter = v)"
      @fecharModal="aplicarSelecionados"
    />
  </modal-padrao>
</template>
<script>
import InstrumentoService from '@common/services/cadastros/InstrumentoService';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: Array, default: () => [] },
    participanteId: { type: String, default: null },
  },
  data() {
    return {
      filtro: {
        nome: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'codigoTag',
            text: this.$t('componentes.input_instrumento.tabela.codigo'),
            sortable: false,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t('componentes.input_instrumento.tabela.tipo'),
            sortable: false,
          },
          {
            value: 'marca.nome',
            text: this.$t('componentes.input_instrumento.tabela.marca'),
            sortable: false,
          },
          {
            value: 'modeloInstrumento.nome',
            text: this.$t('componentes.input_instrumento.tabela.modelo'),
            sortable: false,
          },
          {
            value: 'numeroSerie',
            text: this.$t('componentes.input_instrumento.tabela.numero_serie'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    value: {
      handler() {
        this.definirSelecionados();
      },
    },
  },
  mounted() {
    this.listar();
  },
  methods: {
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    abrirModal() {
      this.$refs['modal-input'].abrirModal();
      this.listar();
      this.focusFiltroGlobal();
    },
    focusFiltroGlobal: async function () {
      await this.$nextTick();
      await this.$nextTick();
      this.$refs['tabela'].focusFiltroGlobal();
    },
    definirSelecionados() {
      this.selecionados = this.tabela.dados.filter((el) =>
        this.value.some((instrumento) => instrumento.id == el.id)
      );
    },
    async listar(paginaAtual = 1, porPagina = 10) {
      if (!this.participanteId) return;

      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
        situacao: 'SemMovimento',
        flagPadrao: false,
        participanteId: this.participanteId,
      };
      
      InstrumentoService.listar(parametros).then((res) => {
        this.tabela.dados = res.data.items;
        this.tabela.quantidadeItens = res.data.totalItems;
        this.tabela.quantidadePaginas = res.data.totalPages;
        this.tabela.paginaAtual = paginaAtual;
        this.tabela.porPagina = porPagina;
      });
    },
    aplicarSelecionados: function () {
      this.$refs['modal-input'].fecharModal();
      this.$emit('associarInstrumentos', this.selecionados);
    },
  },
};
</script>
