<template>
  <div class="row mt-2">
    <input-text
      :value="form.contratante?.text"
      class="col-12 col-md-4"
      :label="$t('modulos.agendamento.formulario.dados.contratante')"
      disabled
    />

    <input-text
      :value="form.contatoContratante?.text"
      class="col-12 col-md-4"
      :label="$t('modulos.agendamento.formulario.dados.contato')"
      disabled
    />
    <input-text
      :value="form.solicitante?.text"
      class="col-12 col-md-4"
      :label="$t('modulos.agendamento.formulario.dados.solicitante')"
      disabled
    />
    <input-text
      :value="converterData(form?.dataHoraInicial)"
      class="col-12 col-md-4"
      :label="$t('modulos.agendamento.formulario.dados.dataHoraInicial')"
      disabled
    />
    <input-text
      :value="converterData(form?.dataHoraFinal)"
      class="col-12 col-md-4"
      :label="$t('modulos.agendamento.formulario.dados.dataHoraFinal')"
      disabled
    />

    <input-colaborador-agendamento
      v-model="form.colaboradores"
      class="col-12 col-md-4"
      :label="$t('modulos.agendamento.formulario.dados.colaboradores')"
      :placeholder="$t('geral.inputs.selecione')"
      :multiplos="true"
      disabled
    />
    <input-text
      v-if="false"
      :value="converterData(form.ordemServico?.text)"
      class="col-12 col-md-3"
      :label="$t('modulos.agendamento.formulario.dados.ordemServico')"
      disabled
    />
    <input-text
      :value="converterData(form.resumo?.inicioViagem)"
      class="col-12 col-md-3"
      :label="$t('modulos.agendamento.formulario.dados.saida_kel')"
      disabled
    />
    <input-text
      :value="converterData(form.resumo?.chegadaCliente)"
      class="col-12 col-md-3"
      :label="$t('modulos.agendamento.formulario.dados.chegada_cliente')"
      disabled
    />
    <input-text
      :value="converterData(form.resumo?.saidaCliente)"
      class="col-12 col-md-3"
      :label="$t('modulos.agendamento.formulario.dados.saida_cliente')"
      disabled
    />
    <input-text
      :value="converterData(form.resumo?.finalViagem)"
      class="col-12 col-md-3"
      :label="$t('modulos.agendamento.formulario.dados.chegada_kel')"
      disabled
    />
    <accordion-padrao
      :alterar-cor="true"
      cor-azul-texto
      :titulo="$t('modulos.agendamento.resumo.titulo.previsto_executado')"
      class="accordion-padrao col-12"
    >
      <tabela-padrao-prime-vue
        :dados="form.resumo?.instrumentosPrevistosExecutados"
        class="mt-2"
        :colunas="tabelas.colunasPrevistoExecutados"
        sem-paginacao
        :mostrar-acoes="false"
        ajustar-altura-linha
        :mostrar-seletor="false"
      />
    </accordion-padrao>
    <accordion-padrao
      :alterar-cor="true"
      cor-azul-texto
      :titulo="$t('modulos.agendamento.resumo.titulo.nao_previsto_executado')"
      class="accordion-padrao col-12"
    >
      <tabela-padrao-prime-vue
        :dados="form.resumo?.instrumentosNaoPrevistosExecutados"
        class="mt-2"
        :colunas="tabelas.colunasNaoPrevistoExecutado"
        sem-paginacao
        :mostrar-acoes="false"
        ajustar-altura-linha
        :mostrar-seletor="false"
      />
    </accordion-padrao>
    <accordion-padrao
      :alterar-cor="true"
      cor-azul-texto
      :titulo="$t('modulos.agendamento.resumo.titulo.previsto_nao_realizado')"
      class="accordion-padrao col-12"
    >
      <tabela-padrao-prime-vue
        :dados="form.resumo?.instrumentosPrevistosNaoExecutados"
        class="mt-2"
        :colunas="tabelas.colunasPrevistoNaoRelaizado"
        sem-paginacao
        :mostrar-acoes="false"
        ajustar-altura-linha
        :mostrar-seletor="false"
      >
        <template v-slot:justificativa="{ slotProps }">
          <div>
            <input-text
              v-model="slotProps.data.justificativa"
              class="flex-fill input-tabela-campos-adicionais mb-3"
              :placeholder="
                $t(
                  'modulos.agendamento.resumo.tabela.previsto_nao_realizado.justificativa'
                )
              "
              :disabled="ehRegistroEvento || ehVisualizar || ehAnaliseCritica"
            />
          </div>
        </template>
      </tabela-padrao-prime-vue>
    </accordion-padrao>
    <accordion-padrao
      :alterar-cor="true"
      cor-azul-texto
      :titulo="$t('modulos.agendamento.resumo.titulo.horas_paradas')"
      class="accordion-padrao col-12"
    >
      <tabela-padrao-prime-vue
        :dados="form.resumo?.horasParadas"
        class="mt-2"
        :colunas="tabelas.ColunasHorasParadas"
        sem-paginacao
        :mostrar-acoes="false"
        ajustar-altura-linha
        :mostrar-seletor="false"
      >
        <template v-slot:numeroHoras="{ slotProps }">
          <span>
            {{ slotProps.data.numeroHoras | formatarHorasParaString }}
          </span>
        </template>
      </tabela-padrao-prime-vue>
    </accordion-padrao>
    <input-textarea
      class="col-12"
      :label="$t('modulos.agendamento.formulario.dados.justificativa')"
      :max="5000"
      :disabled="ehRegistroEvento || ehVisualizar || ehAnaliseCritica"
    />
    <accordion-padrao
      :alterar-cor="true"
      cor-azul-texto
      :titulo="$t('modulos.agendamento.resumo.titulo.assinatura_cliente')"
      class="accordion-padrao col-12"
    >
      <div class="row">
        <input-text
          :value="form.resumo?.assinaturaCliente?.usuario?.nome"
          class="col-12 col-md-6"
          :label="$t('modulos.agendamento.resumo.cliente')"
          disabled
        />
        <input-text
          :value="
            converterData(form.resumo?.assinaturaCliente?.dataHoraAssinatura)
          "
          class="col-12 col-md-6"
          :label="$t('modulos.agendamento.resumo.data_hora_assinatura')"
          disabled
        />
      </div>
    </accordion-padrao>
  </div>
</template>

<script>
import moment from 'moment';
import { InputColaboradorAgendamento } from '@/components/inputs';
import helpers from '@/common/utils/helpers';
export default {
  components: {
    InputColaboradorAgendamento,
  },
  filters: {
    formatarHorasParaString(horas) {
      const totalMinutos = Math.round(horas * 60);
      const horasInteiras = Math.floor(totalMinutos / 60);
      const minutos = totalMinutos % 60;

      const partes = [];
      if (horasInteiras > 0)
        partes.push(`${horasInteiras} hora${horasInteiras > 1 ? 's' : ''}`);
      if (minutos > 0)
        partes.push(`${minutos} minuto${minutos > 1 ? 's' : ''}`);

      return partes.join(' e ') || '0 minutos';
    },
  },
  props: ['form', 'ehRegistroEvento', 'ehVisualizar', 'ehAnaliseCritica'],
  data() {
    return {
      tabelas: {
        colunasPrevistoExecutados: [
          {
            value: 'dataExecucao',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.previsto_executado.data'
            ),
            sortable: false,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
              return '';
            },
          },
          {
            value: 'instrumento.codigo',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.previsto_executado.instrumento'
            ),
            sortable: false,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.previsto_executado.tipo_instrumento'
            ),
            sortable: false,
          },
          {
            value: 'manutencaoPecaCalibracao',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.previsto_executado.manutencaoPecaCalibracao'
            ),
            sortable: false,
            formatter: (v) => {
              if (v) return this.$t(`enums.EnumManutencaoPecaCalibracao.${v}`);
            },
          },
          {
            value: 'codigo',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.previsto_executado.codigo'
            ),
            sortable: false,
          },
          {
            value: 'nome',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.previsto_executado.nome'
            ),
            sortable: false,
          },
          {
            value: 'quantidade',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.previsto_executado.quantidade'
            ),
            sortable: false,
          },
        ],
        colunasNaoPrevistoExecutado: [
          {
            value: 'dataExecucao',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.nao_previsto_executado.data'
            ),
            sortable: false,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
              return '';
            },
          },
          {
            value: 'instrumento.codigo',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.nao_previsto_executado.instrumento'
            ),
            sortable: false,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.nao_previsto_executado.tipo_instrumento'
            ),
            sortable: false,
          },
          {
            value: 'manutencaoPecaCalibracao',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.nao_previsto_executado.manutencaoPecaCalibracao'
            ),
            sortable: false,
            formatter: (v) => {
              if (v) return this.$t(`enums.EnumManutencaoPecaCalibracao.${v}`);
            },
          },
          {
            value: 'codigo',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.nao_previsto_executado.codigo'
            ),
            sortable: false,
          },
          {
            value: 'nome',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.nao_previsto_executado.nome'
            ),
            sortable: false,
          },
          {
            value: 'quantidade',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.nao_previsto_executado.quantidade'
            ),
            sortable: false,
          },
        ],
        colunasPrevistoNaoRelaizado: [
          {
            value: 'instrumento.codigo',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.previsto_nao_realizado.instrumento'
            ),
            sortable: false,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.previsto_nao_realizado.tipo_instrumento'
            ),
            sortable: false,
          },
          {
            value: 'manutencaoPecaCalibracao',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.previsto_nao_realizado.manutencaoPecaCalibracao'
            ),
            sortable: false,
            formatter: (v) => {
              if (v) return this.$t(`enums.EnumManutencaoPecaCalibracao.${v}`);
            },
          },
          {
            value: 'codigo',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.previsto_nao_realizado.codigo'
            ),
            sortable: false,
          },
          {
            value: 'nome',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.previsto_nao_realizado.nome'
            ),
            sortable: false,
          },
          {
            value: 'quantidade',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.previsto_nao_realizado.quantidade'
            ),
            sortable: false,
          },
          {
            value: 'justificativa',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.previsto_nao_realizado.justificativa'
            ),
            sortable: false,
          },
        ],
        ColunasHorasParadas: [
          {
            value: 'dataCheckIn',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.horas_paradas.data'
            ),
            sortable: false,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
              return '';
            },
          },
          {
            value: 'numeroHoras',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.horas_paradas.numero_horas'
            ),
            sortable: false,
          },
          {
            value: 'horaCheckIn',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.horas_paradas.hora_inicial'
            ),
            sortable: false,
          },
          {
            value: 'horaAtendimento',
            text: this.$t(
              'modulos.agendamento.resumo.tabela.horas_paradas.hora_final'
            ),
            sortable: false,
          },
        ],
      },
    };
  },
  methods: {
    converterData(data) {
      if (!data) return '';
      return moment.utc(data).format('DD/MM/YYYY HH:mm');
    },
  },
};
</script>

<style scoped>
/* Add your component styles here */
</style>
